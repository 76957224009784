<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar perfil' : 'Nuevo perfil']"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="w-full"
    width="720px"
    okText="Guardar"
    :maskClosable="false"
    :destroyOnClose="true"
    :afterClose="fetchPerfiles"
  >
    <a-form :model="formState" :rules="formRules" ref="formRef" v-bind="formLayout">
      <a-form-item label="Nombre" name="denominacion">
        <a-input v-model:value="formState.denominacion" placeholder="ejm. SUPERVISOR" />
      </a-form-item>

      <a-form-item label="Descripción" name="descripcion">
        <a-textarea v-model:value="formState.descripcion" />
      </a-form-item>

      <p v-if="isLoading" class="text-center">Cargando...</p>
      <div v-else class="ml-8">
        <h1 class="my-2">Módulos y accesos del sistema :</h1>
        <a-tree
          checkable
          class="overflow-x-scroll"
          :selectable="false"
          :tree-data="treeData.data"
          v-model:expandedKeys="treeData.expandedKeys"
          v-model:checkedKeys="treeData.checkedKeys"
        />
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import { formRules, formLayout, groupAccesos } from "./utilsPerfil";
import { generateArrayAddDelete } from "@/utils";
import PerfilApi from "@/api/perfil";

export default {
  props: {
    perfil: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchPerfiles", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const formState = reactive(props.perfil);
    const perfilStatic = props.perfil;
    const denominacionStatic = props.perfil.denominacion;
    const isLoading = ref(false);
    const isEdit = !!perfilStatic.idrol;
    const treeData = reactive({
      data: [],
      expandedKeys: isEdit ? formState.accesos.map((a) => "modulo-" + a.idmodulo) : [],
      checkedKeys: isEdit ? formState.accesos.map((a) => a.idopcion) : []
    });

    console.log(toRaw(formState));

    // Trae todos los accesos al sistema (rutas)
    const fetchOpcionesSistema = () => {
      isLoading.value = true;
      PerfilApi.getOpcionesSistema()
        .then(async (response) => {
          treeData.data = await groupAccesos(response.data);
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      fetchOpcionesSistema();
    });

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          // console.log(toRaw(formState));
          console.log(treeData.checkedKeys);

          const payload = toRaw(formState);
          const checkedKeys = Object.values(treeData.checkedKeys);
          let response = {};
          let msgNotificacion = "";

          try {
            if (!checkedKeys.length) return notificaAccesoRequerido(); // Revisar que haya marcado al menos un acceso
            if (isEdit) {
              const accesosIniciales = perfilStatic.accesos.map((a) => a.idopcion) || [];
              const isOptionsEquals =
                JSON.stringify(accesosIniciales) == JSON.stringify(checkedKeys);

              const isDenominacionEquals = formState.denominacion !== denominacionStatic;

              if (isOptionsEquals) {
                // solo actualiza descripcion o nombre
                response = await PerfilApi.editOne(
                  payload,
                  perfilStatic.idrol,
                  isDenominacionEquals
                );
                msgNotificacion = "El perfil ha sido modificado satisfactoriamente.";
              } else {
                // actualizar todo, descripcion, nombre y rolopcion
                response = await PerfilApi.editOne(
                  payload,
                  perfilStatic.idrol,
                  isDenominacionEquals
                );
                msgNotificacion = "El perfil ha sido modificado satisfactoriamente.";

                const { arrayAdd, arrayDelete } = generateArrayAddDelete(
                  accesosIniciales,
                  checkedKeys
                );
                if (arrayAdd.length) {
                  const payloadRoleOpcion = atachRoleOpcion(arrayAdd, perfilStatic.idrol);
                  await PerfilApi.asignOpciones(payloadRoleOpcion);
                }
                if (arrayDelete.length) {
                  const arrayIdsDelete = getRolOpcionIds(arrayDelete);
                  await PerfilApi.deleteManyOpcion(arrayIdsDelete, perfilStatic.idrol);
                }
              }
            } else {
              response = await PerfilApi.addOne(payload);
              const idrol = response.data[0].id;
              const payloadRoleOpcion = atachRoleOpcion(checkedKeys, idrol);
              await PerfilApi.asignOpciones(payloadRoleOpcion);
              msgNotificacion = "El perfil ha sido registrado satisfactoriamente.";
            }

            if (response.status) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });

              emit("closeModal");
              emit("fetchPerfiles");
              emit("clearSelectedArrays");
            }
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: "El perfil ya se encuentra registrado"
              // description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    /**
     * atachRoleOpcion
     * ===============
     * Crea el formato para agregar opciones
     * según lo que pide la api
     * @param {Array} array
     * @param {String} id id del rol
     * @returns {String} idopcion,idrol ejm: 14,39;15,39
     */
    const atachRoleOpcion = (array = [], id = "") => {
      if (!array.length) return [];
      return array.map((i) => i + "," + id).join(";");
    };

    /**
     * getRolOpcionIds
     * ==============
     * trae ids para eliminarlos
     * @param {Array} arrayDelete
     * @returns {Array} ids rol opcion a eliminar
     * https://stackoverflow.com/a/31201324/9868383
     */
    function getRolOpcionIds(arrayDelete = []) {
      return perfilStatic.accesos
        .filter((a) => arrayDelete.includes(a.idopcion))
        .map((a) => a.idrolopcion);
    }

    const notificaAccesoRequerido = () => {
      notification.error({
        message: "Operación inválida",
        description: "Debes asignar al menos un acceso al perfil"
      });
    };

    const handleCancel = () => {
      emit("clearSelectedArrays");
      emit("fetchPerfiles");
      emit("closeModal");
    };

    const closeModal = () => {
      emit("closeModal");
    };

    function fetchPerfiles() {
      emit("fetchPerfiles");
    }

    return {
      formRef,
      isEdit,
      formLayout,
      formState,
      handleOk,
      closeModal,
      handleCancel,
      formRules,
      isLoading,
      treeData,
      fetchPerfiles
    };
  }
};
</script>
