/**
 * Contiene datos para las tablas y formularios
 */
export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 150,
    ellipsis: true
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "descripcion",
    key: "descripcion",
    width: 180,
    ellipsis: true
  },
  {
    title: "MODULOS",
    dataIndex: "modulos",
    key: "modulos",
    width: 220,
    slots: { customRender: "modulos" }
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];

export const formRules = {
  denominacion: [
    {
      required: true,
      message: "El campo nombre no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 50,
      message: "La cantidad máxima de caracteres es de 50",
      trigger: "blur"
    }
  ],
  description: [
    {
      required: true,
      message: "El campo valor no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 200,
      message: "La cantidad máxima de caracteres es de 200",
      trigger: "blur"
    }
  ]
};

export const formLayout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 18,
    offset: 1
  }
};

/**
 * groupPerfiles
 * ENTRADA: 
[
  {
    "idopcion": "1",
    "denominacion": "Establecimientos",
    "ruta": "/establecimiento",
    "orden": "1",
    "idmodulo": "5",
    "modulo": "Registro"
}
]

SALIDA:
[
  {
    "idmodulo": "5",
    "title": "Registro",
    "key": "modulo-5",
    "checkable": false,
    "children": [
        {
            "title": "Establecimientos",
            "key": "1"
        },
        {
            "title": "Niños",
            "key": "2"
        },
        {
            "title": "Gestantes",
            "key": "3"
        }
    ]
}
]
 */

/**
 * groupAccesos
 * @param {Array} entrada
 * @returns {Array}
 *
 *
 */
export const groupAccesos = (entrada = []) => {
  return new Promise((resolve, reject) => {
    if (!entrada) reject([]);
    if (!entrada.length) resolve([]);

    resolve(
      entrada.reduce((a, b) => {
        var i = a.findIndex((x) => x.idmodulo === b.idmodulo);

        if (i === -1) {
          a.push({
            idmodulo: b.idmodulo, // importante poner el id para que lo agrupe
            title: b.modulo,
            key: "modulo-" + b.idmodulo,
            checkable: false,
            children: [
              {
                title: b.denominacion,
                key: b.idopcion
              }
            ]
          });
        } else {
          a[i].children = [...a[i].children, { title: b.denominacion, key: b.idopcion }];
        }

        return a;
      }, [])
    );
  });
};
