<template>
  <a-form layout="horizontal" :model="formState" :rules="rules" ref="formRefFilter">
    <div class="md:flex">
      <div>
        <a-form-item label="Estado" name="filter" class="mb-2">
          <a-select v-model:value="formState.filter">
            <a-select-option value="all">Todos</a-select-option>
            <a-select-option value="1">Activos</a-select-option>
            <a-select-option value="0">Desactivados</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Buscar" class="mb-0" name="search">
          <a-input v-model:value="formState.search" />
        </a-form-item>
      </div>

      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    // icons
    SearchOutlined,
    ClearOutlined
  },

  emits: ["fetchPerfiles", "onFilterData"],
  setup(props, { emit }) {
    const formRefFilter = ref();
    const isDisabled = ref(true);
    const store = useStore();
    const formState = reactive({
      filter: store.getters["perfil/filter"],
      search: store.getters["perfil/search"]
    });

    // const applyFilters = () => {
    //   isDisabled.value = false;
    //   emit("onFilterData", formState.filter);
    // };

    const applyFilters = () => {
      formRefFilter.value
        .validate()
        .then(async () => {
          const { filter, search } = toRaw(formState);
          isDisabled.value = false;
          store.dispatch("perfil/setFilter", filter);
          store.dispatch("perfil/setSearch", search);
          emit("fetchPerfiles");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      formState.filter = "all";
      formState.search = "";
      store.dispatch("perfil/cleanFilters");
      emit("fetchPerfiles");
      isDisabled.value = true;
    };

    // const cleanFilters = () => {
    //   emit("fetchPerfiles");
    //   formState.filter = "all";
    //   isDisabled.value = true;
    // };

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
