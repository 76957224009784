import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/Service/V1";
const API_SERVICE_ARRAY = "/api/ServiceArray/V1";
const API_SERVICE_ARRAY_BY_ROWS = "/api/ServiceArrayByRows/V1";
const API_SERVICE_DELETE_USUARIO = "/api/auth/validar_roles";

export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_roles_roles_opcion",
          type: "string"
        }
      ]
    };

    if (payload?.filter && payload?.filter !== "all") {
      const { filter = "all", search = "" } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "rol.activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "rol.denominacion|rol.descripcion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload?.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "rol.denominacion|rol.descripcion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "rol",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string|unique"
        },
        {
          name: "descripcion",
          value: payload.descripcion,
          type: "string"
        }
      ]
    };
    return post(API_SERVICE, request);
  },

  asignOpciones: (payload) => {
    const request = {
      _type: 2,
      _table: "rolopcion",
      _petitions: [
        {
          name: "campos",
          value: "idopcion,idrol",
          type: "array"
        },
        {
          name: "values",
          value: payload,
          type: "array"
        }
      ]
    };

    return post(API_SERVICE_ARRAY, request);
  },

  getOpcionesSistema: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_opcion",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "o.activo",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: "1",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  editOne: (payload, idrol, isUnique) => {
    console.log(isUnique);
    const request = {
      _type: 2,
      _table: "rol",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "descripcion",
          value: payload.descripcion,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idrol, request);
  },

  deleteMany: (payload) => {
    const request = {
      _type: 3,
      _table: "rol",
      _petitions: [
        {
          name: "@ids",
          value: payload.join("|"),
          type: "string"
        },
        {
          name: "eliminado",
          value: payload.map(() => "1").join("|"),
          type: "string"
        }
      ]
    };

    return put(API_SERVICE_ARRAY, request);
  },

  deleteManyOpcion: (arrayIdsDelete = [], idrol = "") => {
    const request = {
      _type: 3,
      _table: "rolopcion",
      _petitions: [
        {
          name: "idrolopcion|idrol",
          value: arrayIdsDelete.map((op) => op + "," + idrol).join("|"), //genera: 68,39|69,39
          type: "string"
        },
        {
          name: "eliminado",
          value: arrayIdsDelete.map(() => "1").join("|"), // genera: 1|1
          type: "string"
        }
      ]
    };

    console.log(request);
    return put(API_SERVICE_ARRAY_BY_ROWS, request);
  },

  changeStateMany: (payload) => {
    console.log(payload);
    const request = {
      _type: 3,
      _table: "rol",
      _petitions: [
        {
          name: "@ids",
          value: payload.ids.join("|"),
          type: "string"
        },
        {
          name: "activo",
          value: payload.activos.join("|"),
          type: "string"
        }
      ]
    };

    console.log(request);

    return put(API_SERVICE_ARRAY, request);
  },

  deleteUsuarioRol: (idrol) => {
    const request = {
      _type: 1,
      _table: "undefiend",
      _petitions: [
        {
          name: "id",
          value: idrol,
          type: "int"
        },
        {
          name: "eliminado",
          value: "1",
          type: "int"
        },
        {
          name: "valor",
          value: 4,
          type: "int"
        }
      ]
    };
    return post(API_SERVICE_DELETE_USUARIO, request);
  },

  ChangeEstadoUsuarioRol: (idrol, valor) => {
    var valorold = valor == 1 ? 1 : 4;
    const request = {
      _type: 1,
      _table: "undefiend",
      _petitions: [
        {
          name: "id",
          value: idrol,
          type: "int"
        },
        {
          name: "activo",
          value: valor,
          type: "int"
        },
        {
          name: "valor",
          value: valorold,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_DELETE_USUARIO, request);
  },

  countUsuarioByRol: (idrol) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_count_usuarios_byrol",
          type: "string"
        },
        {
          name: "@IDROL",
          value: idrol,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
