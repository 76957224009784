<template>
  <a-page-header @back="() => $router.go(-1)" title="PERFILES" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Configuración</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Perfiles</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <!--------------- Filtros--------------->
    <fieldset class="border border-solid w-full md:w-1/2 border-gray-300 p-3 px-8 lg:w-auto">
      <legend class="text-sm text-center">Filtros</legend>
      <PerfilesFiltro @fetchPerfiles="fetchPerfiles" @onFilterData="onFilterData" />
    </fieldset>

    <!--------------- Acciones--------------->
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Acciones</legend>

      <PerfilesAcciones
        :perfil="perfil"
        :isDisabled="isDisabled"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleChangeState="handleChangeState"
      />
    </fieldset>
  </div>

  <!--------------- Tabla --------------->
  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="{ defaultCurrent: 1, size: 'large' }"
      rowKey="idrol"
      :loading="isLoading"
      :custom-row="customRow"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ record }">
        <span> {{ data.indexOf(record) + 1 }} </span>
      </template>

      <template #modulos="{ text: modulos }">
        <span>
          {{ modulos.length ? modulos.join(", ") : "Sin accesos." }}
        </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-wrap items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex flex-wrap">
      <button class="ml-4" @click="fetchPerfiles">
        <ReloadOutlined />
      </button>
    </div>

    <p>Total de registros: {{ totalDocs }}</p>
  </footer>

  <!--------------- Modal--------------->
  <PerfilesAddEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :perfil="perfil"
    @closeModal="closeModal"
    @fetchPerfiles="fetchPerfiles"
    @clearSelectedArrays="clearSelectedArrays"
  />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted, createVNode, onUnmounted } from "vue";
import { useStore } from "vuex";
import { columns } from "./utilsPerfil";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import PerfilesAddEdit from "./PerfilesAddEdit.vue";
import PerfilesFiltro from "./PerfilesFiltro.vue";
import PerfilesAcciones from "./PerfilesAcciones.vue";
import PerfilApi from "@/api/perfil";
import UsuariosApi from "@/api/user";
import { Modal, notification } from "ant-design-vue";
import { groupPerfiles, filtraPerfilSuperAdmin } from "@/utils";

export default {
  name: "AppPerfiles",
  components: {
    ReloadOutlined,
    PerfilesAddEdit,
    PerfilesFiltro,
    PerfilesAcciones
  },
  setup() {
    const store = useStore();
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const isLoading = ref(false);
    const nrousuariosAfectados = ref(0);
    const perfil = ref(null);
    const data = ref([]);
    const state = reactive({
      selectedRowKeys: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    const currentPage = store.getters["perfil/currentPage"];
    const { establecimiento } = store.state.user.usuario;

    // ------- trae datos de la api --------
    const fetchPerfiles = () => {
      const filter = store.getters["perfil/filter"];
      const search = store.getters["perfil/search"];

      isLoading.value = true;
      PerfilApi.getAll({ filter, search })
        .then(async (response) => {
          console.log("PERFILES", response);
          data.value = await groupPerfiles(response.data);
          data.value = await filtraPerfilSuperAdmin(data.value);

          totalDocs.value = data.value.length;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Configuración", "Perfiles", establecimiento);
      fetchPerfiles();
    });

    onUnmounted(() => {
      store.dispatch("perfil/cleanFilters");
    });

    const onFilterData = (filtro) => {
      data.value = data.value.filter((i) => i.activo === filtro);
    };

    // -------- se ejecuta cuando selecciona una fila --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          perfil.value = toRaw(selectedRows[0]);
        }
      };
    });

    // ------- desactiva la seleccion actual -------
    const customRow = () => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    // -------- trae datos al cambiar de pagina --------
    const onChangePage = (newPage) => {
      store.dispatch("perfil/setCurrentPage", newPage);
      fetchPerfiles();
      clearSelectedArrays();
    };

    watch(state, () => {
      isDisabled.edit = !state.selectedRowKeys.length;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    // ------- acciones -------
    const handleAdd = () => {
      perfil.value = {
        denominacion: "",
        descripcion: "",
        accesos: []
      };
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const handleChangeState = async () => {
      await fetchNrousuariosAfectados(perfil.value.idrol);

      const actionMsg = perfil.value.activo === "1" ? "desactivado" : "activado";
      const verboActionMsg = perfil.value.activo === "1" ? "desactivar" : "activar";

      const contentMsg =
        perfil.value.activo === "1"
          ? `¿Estás seguro de desactivar el perfil '${perfil.value.denominacion}'?, 
          este perfil esta siendo utilizado por (${nrousuariosAfectados.value}) usuarios,
          es posible que estos usuarios ya no tengan acceso al sistema.`
          : `¿Estás seguro de activar el perfil '${perfil.value.denominacion}'?`;

      Modal.confirm({
        title: () => `¿Estás seguro de ${verboActionMsg}?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => contentMsg,
        okText: () => `Sí, ${verboActionMsg}`,
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const payload = {
            ids: toRaw(state.selectedRowKeys),
            activos: [perfil.value.activo === "1" ? "0" : "1"]
          };

          var valor = perfil.value.activo === "1" ? "0" : "1";

          PerfilApi.changeStateMany(payload)
            .then(() => {
              fetchPerfiles();
              clearSelectedArrays();

              notification.success({
                message: "Operación exitosa",
                description: `Se ha ${actionMsg} el perfil satistactoriamente.`
              });

              PerfilApi.ChangeEstadoUsuarioRol(perfil.value.idrol, valor)
                .then(() => {})
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    const fetchNrousuariosAfectados = (idrol) => {
      return new Promise((resolve, reject) => {
        PerfilApi.countUsuarioByRol(idrol)
          .then((res) => {
            nrousuariosAfectados.value = res.data[0].nrousuarios;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    };

    const handleDelete = async () => {
      await fetchNrousuariosAfectados(perfil.value.idrol);

      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          `¿Estás seguro de eliminar el perfil '${perfil.value.denominacion}'?, 
          este perfil esta siendo utilizado por (${nrousuariosAfectados.value}) usuarios,
          es posible que estos usuarios ya no tengan acceso al sistema.`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",
        onOk() {
          PerfilApi.deleteMany(toRaw(state.selectedRowKeys))
            .then(() => {
              fetchPerfiles();
              clearSelectedArrays();
              notification.success({
                message: "Operación exitosa",
                description: `Se ha eliminado el perfil satistactoriamente.`
              });

              PerfilApi.deleteUsuarioRol(perfil.value.idrol)
                .then(() => {})
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }
      });
    };

    return {
      isLoading,
      data,
      columns,
      rowSelection,
      handleAdd,
      handleEdit,
      handleDelete,
      handleChangeState,
      pageSize,
      currentPage,
      totalDocs,
      isVisible,
      closeModal,
      fetchPerfiles,
      perfil,
      isDisabled,
      clearSelectedArrays,
      onChangePage,
      customRow,
      onFilterData
    };
  }
};
</script>

<style></style>
